import React from 'react';
import {Helmet} from 'react-helmet'
import {graphql} from 'gatsby'
import ProgressiveImageContainer from "../components/ProgressiveImageContainer";
import {HTMLContent} from "../components/Content";
import JoinForm from "../components/JoinForm";

const HomePageTemplate = (props) => {
    const {data: {markdownRemark: {html, frontmatter: {title, meta_title, meta_description, background}}}} = props

    return (
        <>
            <Helmet
                htmlAttributes={{
                    lang: `en`,
                }}
                title={meta_title}
                meta={[
                    {
                        name: `description`,
                        content: meta_description,
                    },
                    {
                        name: `viewport`,
                        content: `width=device-width, initial-scale=1`,
                    },
                ]}
            />

            <div>
                <h1>{title}</h1>

                <ProgressiveImageContainer image={background} alt={title}/>

                <HTMLContent content={html}/>

                <JoinForm/>
            </div>
        </>
    )
}

export default HomePageTemplate

export const homePageQuery = graphql`
    query HomePage($id: String!) {
        markdownRemark(id: { eq: $id }) {
            html
            frontmatter {
                title
                background {
                    childImageSharp {
                        fluid(maxWidth: 700, quality: 72) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                    publicURL
                }
                meta_title
                meta_description
            }
        }
    }
`
