import React from 'react'
import {Formik, Field, Form} from 'formik'
import validationSchema from "./validationSchema";
import {encode} from "../../utils";


const JoinForm = () => {
    return (
        <Formik
            initialValues={{email: ''}}
            validationSchema={validationSchema}
            onSubmit={(values, {setSubmitting, setErrors, setStatus, resetForm}) => {
                fetch("/?no-cache=1", {
                    method: 'POST',
                    headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                    body: encode({
                        'form-name': 'Join',
                        ...values,
                    }),
                })
                    .then(() => {
                        resetForm({email: ''})
                        alert('Thank You!')
                        setStatus({success: true})
                    })
                    .catch(error => {
                        setStatus({success: false})
                        setSubmitting(false)
                        setErrors({submit: error.message})
                    })
            }}
            render={({touched, errors, isSubmitting}) =>
                <Form name='Join' data-netlify='true' data-netlify-honeypot='bot-field'>
                    <div>
                        <Field type='email' name='email' placeholder='e-Mail' aria-label='Email' required/>
                        {touched.email && errors.email && <p className='danger'>{errors.email}</p>}
                    </div>
                    <div>
                        <input aria-label='Submit' name='submit' type='submit' disabled={isSubmitting} value='Submit'/>
                    </div>
                </Form>
            }
        />
    );
};

export default JoinForm;
